import { useState, useEffect } from "react";
import BarChart from "../../charts/BarCharts.jsx";
import { baseUrl, tailwindConfig } from "../../utils/Utils";

function BarStat() {
  const [graphData, setGraphData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("All");
  const [chartData, setChartData] = useState(null);
  const [seasons, setSeasons] = useState(["All"]);
  const organizationToken = localStorage.getItem("token");

  const fetchSeasons = async () => {
    try {
      const response = await fetch(`${baseUrl}/metrics/getAllSeasons`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${organizationToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSeasons(["All", ...data.data]);
      } else {
        console.error("Failed to fetch seasons");
      }
    } catch (error) {
      console.error("Error fetching seasons:", error);
    }
  };

  const fetchGraphData = async (season) => {
    try {
      const seasonQuery = season === "All" ? "" : `?growingSeason=${season}`;
      const response = await fetch(
        `${baseUrl}/metrics/getGraphMetrics${seasonQuery}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${organizationToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setGraphData(data.data || []);
      } else {
        console.error("Failed to fetch graph data");
        setGraphData([]);
      }
    } catch (error) {
      console.error("Error fetching graph data:", error);
      setGraphData([]);
    }
  };

  useEffect(() => {
    fetchSeasons();
  }, []);

  useEffect(() => {
    setChartData(null);
    fetchGraphData(selectedSeason);
  }, [selectedSeason]);

  useEffect(() => {
    if (graphData.length > 0) {
      setChartData({
        labels: graphData.map((item) => item.cropName),
        datasets: [
          {
            label: "Yield (Metric Tons)",
            data: graphData.map((item) => item.totalYield),
            backgroundColor: tailwindConfig().theme.colors.blue[400],
            hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
            barPercentage: 0.6,
            categoryPercentage: 0.6,
          },
          {
            label: "Number of Farmers Cultivating",
            data: graphData.map((item) => item.farmerCount),
            backgroundColor: tailwindConfig().theme.colors.green[400],
            hoverBackgroundColor: tailwindConfig().theme.colors.green[500],
            barPercentage: 0.6,
            categoryPercentage: 0.6,
          },
        ],
      });
    } else {
      setChartData(null);
    }
  }, [graphData]);

  return (
    <div className="flex rounded-xl flex-col col-span-full sm:col-span-full xl:col-span-8 bg-white shadow-lg border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100 flex justify-between">
        <h2 className="font-semibold text-slate-800">
          Crop Yield vs. Farmers Cultivating
        </h2>
        <select
          className="border py-1 rounded-md"
          value={selectedSeason}
          onChange={(e) => setSelectedSeason(e.target.value)}
          style={{ padding: "1 5 1 0" }}
        >
          {seasons.map((season) => (
            <option key={season} value={season}>
              {season}
            </option>
          ))}
        </select>
      </header>
      {chartData ? (
        <BarChart key={selectedSeason} data={chartData} />
      ) : (
        <p className="p-5 text-center">
          No data available for the selected season
        </p>
      )}
    </div>
  );
}

export default BarStat;
