import React, { useEffect, useRef, useState } from "react";
import { loadGoogleMapsScript } from "../utils/Utils";

const PolygonMapComponent = ({ onPolygonComplete, onAddressSelect }) => {
  const mapRef = useRef(null);
  const searchInputRef = useRef(null);
  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);
  const [marker, setMarker] = useState(null);
  const [area, setArea] = useState(0);

  const GOOGLE_MAPS_API_URL =
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY_SCRIPT_URL;

  useEffect(() => {
    const initializeMap = async () => {
      try {
        await loadGoogleMapsScript(GOOGLE_MAPS_API_URL);
        if (!window.google)
          throw new Error("'google' is undefined after script load.");

        // Initialize the map
        const mapInstance = new window.google.maps.Map(mapRef.current, {
          center: { lat: 20.5937, lng: 78.9629 },
          zoom: 5,
        });
        setMap(mapInstance);

        // Initialize a marker (hidden by default)
        const locationMarker = new window.google.maps.Marker({
          map: mapInstance,
          visible: false,
        });
        setMarker(locationMarker);

        // Add Autocomplete for the search input
        const autocomplete = new window.google.maps.places.Autocomplete(
          searchInputRef.current
        );
        autocomplete.bindTo("bounds", mapInstance);
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (!place.geometry) return;

          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();

          // Update marker position
          updateMarkerLocation(lat, lng, mapInstance, locationMarker);

          // Extract and send address components to the parent component
          const addressComponents = parseAddressComponents(
            place.address_components
          );
          onAddressSelect({
            ...addressComponents,
            lat,
            lng,
          });
        });

        // Add Drawing Manager
        const drawingManagerInstance =
          new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
              editable: true,
              draggable: true,
            },
          });
        drawingManagerInstance.setMap(mapInstance);
        setDrawingManager(drawingManagerInstance);

        // Handle polygon complete
        window.google.maps.event.addListener(
          drawingManagerInstance,
          "overlaycomplete",
          (event) => {
            if (polygon) {
              polygon.setMap(null); // Remove the previous polygon
            }

            const newPolygon = event.overlay;
            newPolygon.setEditable(true);
            newPolygon.setDraggable(true);
            setPolygon(newPolygon);

            updatePolygonDetails(newPolygon);

            // Listen for changes when the polygon is moved
            window.google.maps.event.addListener(
              newPolygon.getPath(),
              "set_at",
              () => updatePolygonDetails(newPolygon)
            );
            window.google.maps.event.addListener(
              newPolygon.getPath(),
              "insert_at",
              () => updatePolygonDetails(newPolygon)
            );
            window.google.maps.event.addListener(newPolygon, "dragend", () =>
              updatePolygonDetails(newPolygon)
            );
          }
        );
      } catch (error) {
        console.error("Failed to load Google Maps:", error);
      }
    };

    initializeMap();
  }, [GOOGLE_MAPS_API_URL]);

  // Function to update marker location
  const updateMarkerLocation = (lat, lng, mapInstance, locationMarker) => {
    locationMarker.setPosition({ lat, lng });
    locationMarker.setVisible(true);
    mapInstance.panTo({ lat, lng });
    mapInstance.setZoom(15);
  };

  // Function to update polygon details when moved or changed
  const updatePolygonDetails = (polygonInstance) => {
    // Calculate area
    const areaInSqMeters = window.google.maps.geometry.spherical.computeArea(
      polygonInstance.getPath()
    );
    setArea(areaInSqMeters.toFixed(2));

    // Extract coordinates
    const coordinates = polygonInstance
      .getPath()
      .getArray()
      .map((point) => ({ lat: point.lat(), lng: point.lng() }));

    // Get the center of the polygon
    const bounds = new window.google.maps.LatLngBounds();
    coordinates.forEach((coord) => bounds.extend(coord));
    const center = bounds.getCenter();

    // Reverse Geocode the new polygon center
    reverseGeocodeLocation(center.lat(), center.lng()).then((addressData) => {
      onPolygonComplete({ area: areaInSqMeters.toFixed(2), coordinates });
      onAddressSelect({
        ...addressData,
        lat: center.lat(),
        lng: center.lng(),
      });
    });
  };

  // Reverse Geocode to fetch address from lat/lng
  const reverseGeocodeLocation = (lat, lng) => {
    return new Promise((resolve) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          resolve(parseAddressComponents(results[0].address_components));
        } else {
          resolve({});
        }
      });
    });
  };

  const parseAddressComponents = (components) => {
    const getComponent = (type) =>
      components.find((component) => component.types.includes(type))
        ?.long_name || "";

    return {
      localArea: getComponent("sublocality_level_1"),
      village: getComponent("locality"),
      district: getComponent("administrative_area_level_3"),
      taluka: getComponent("administrative_area_level_2"),
      state: getComponent("administrative_area_level_1"),
      country: getComponent("country"),
      pincode: getComponent("postal_code"),
    };
  };

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <input
          ref={searchInputRef}
          type="text"
          placeholder="Search location"
          style={{
            width: "100%",
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        />
      </div>
      <div
        ref={mapRef}
        style={{
          width: "100%",
          height: "400px",
          border: "1px solid #ddd",
          borderRadius: "4px",
        }}
      ></div>
    </div>
  );
};

export default PolygonMapComponent;
