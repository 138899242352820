import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { baseUrl, EndPoint } from "../../utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import useBreakpoint from "../../utils/useBreakpoint";
import FileUploadComponent from "./FileUploadComponent";
import jwtDecode from "jwt-decode";
import PolygonMapComponent from "../PolygonMapComponent";

const INITIAL_VALUES = {
  name: "",
  tag: "",
  location: { lang: "", lat: "" },
  area: "",
  soil: "",
  gatNo: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  tag: Yup.string().required("Tag is required"),
  // location: Yup.object({
  //   lang: Yup.string()
  //     .required("Longitude is required")
  //     .matches(/^-?\d+(\.\d+)?$/, "Invalid longitude"),
  //   lat: Yup.string()
  //     .required("Latitude is required")
  //     .matches(/^-?\d+(\.\d+)?$/, "Invalid latitude"),
  // }),
  // area: Yup.number()
  //   .required("Area is required")
  //   .positive("Area must be positive"),
  soil: Yup.string().required("Soil type is required"),
  gatNo: Yup.string().required("Gat Number is required"),
});

function FieldModal({
  userData,
  setModalOpen,
  modalOpen,
  setSelectedField,
  selectedField,
  setRefetch,
  farmData,
}) {
  const modalContent = useRef(null);
  const organizationId = useSelector(
    (state) => state?.auth?.user?.organizations?.[0]?._id
  );
  const { isMobile } = useBreakpoint();
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    if (selectedField) {
      const formatted = {
        name: selectedField?.name || "",
        tag: selectedField?.tag || "",
        location: {
          lang: selectedField?.location?.lang || "",
          lat: selectedField?.location?.lat || "",
        },
        area: selectedField?.area || "",
        soil: selectedField?.soil || "",
        gatNo: selectedField?.gatNo || "",
      };
      Object.keys(formatted).forEach(
        (key) => (INITIAL_VALUES[key] = formatted[key])
      );

      setUploadedFiles(selectedField?.field_doc || []);
    }
  }, [selectedField]);

  const uploadToCdnDocuments = async (uploadedFiles) => {
    const updatedfiles = [...uploadedFiles];

    for (let i = 0; i < uploadedFiles.length; i++) {
      let formData = new FormData();
      formData.append("item", uploadedFiles[i]?.details);
      const endPoint = EndPoint;
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const userId = decoded?.id;
      try {
        const response = await axios.post(endPoint, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
          },
        });
        // setImageData(response.data);

        const url = response?.data?.result?.url;
        if (url) {
          updatedfiles[i] = {
            ...updatedfiles[i],
            url: url,
            userId: userId,
          };
        }
      } catch (error) {
        console.log(error);
      }
    }
    const finalFiles = updatedfiles?.map((file) => ({
      name: file.name,
      documentType: file.documentType,
      url: file.url,
      userId: file.userId,
      documentCategory: file.documentCategory,
    }));

    return finalFiles;
  };

  const documentSubmit = async (documentsData) => {
    const token = localStorage.getItem("token");

    const endpoint = `${baseUrl}/document`;

    let documentsIdData = [];

    try {
      const response = await axios.post(endpoint, documentsData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      documentsIdData = response?.data?.body || [];
      toast.success("Documents added suucessfully!");
    } catch (error) {
      console.error("Error uploading documents:", error);
    }

    return documentsIdData;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const token = localStorage.getItem("token");

    let documentsIds = [];

    if (selectedField) {
      const newUploadedFiles = uploadedFiles?.filter((file) => !file._id);
      const filesdeleted = uploadedFiles?.filter((file) => file._id);
      const filesIds = filesdeleted?.map((item) => item._id);

      const documentsData =
        newUploadedFiles.length > 0
          ? await uploadToCdnDocuments(newUploadedFiles)
          : [];

      const documentsIdsResponse =
        documentsData.length > 0 ? await documentSubmit(documentsData) : [];

      const newDocumentIds = documentsIdsResponse?.map((item) => item._id);

      documentsIds = [...filesIds, ...newDocumentIds];
    } else {
      const documentsData = uploadedFiles
        ? await uploadToCdnDocuments(uploadedFiles)
        : [];

      const documentsIdsResponse = documentsData
        ? await documentSubmit(documentsData)
        : [];

      documentsIds = documentsIdsResponse?.map((item) => item._id);
    }

    values["documents"] = documentsIds;

    const finalValues = {
      ...values,
      location: {
        ...values.location,
        lat: values.location.lat.toString(),
        lang: values.location.lang.toString(),
      },
      farmerId: userData?.[0]?._id,
      farmId: farmData?.[0]?._id,
      organizationId,
      documents: documentsIds,
    };

    const addMessage = selectedField
      ? "Updating field details..."
      : "Adding field details...";
    const successMessage = selectedField
      ? "Field details updated successfully 👍"
      : "Field details added successfully 👍";
    const errorMessage = selectedField
      ? "Error updating field details 🤯"
      : "Error adding field details 🤯";

    toast.promise(
      new Promise(async (resolve, reject) => {
        const endpoint = `${baseUrl}/field/${selectedField?._id}`;
        try {
          const response = selectedField
            ? await axios.patch(endpoint, finalValues, {
                headers: { Authorization: `Bearer ${token}` },
              })
            : await axios.post(`${baseUrl}/field/`, finalValues, {
                headers: { Authorization: `Bearer ${token}` },
              });

          if (response.status === 200) {
            resolve(response);
            setModalOpen(false);
            setSelectedField(null);
            setRefetch(true);
          }
        } catch (error) {
          reject(error);
          console.error(error);
        }
      }),
      {
        pending: addMessage,
        success: successMessage,
        error: errorMessage,
      }
    );
    setSubmitting(false);
  };

  return (
    <>
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      <Transition
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white overflow-auto max-w-6xl w-full max-h-full rounded shadow-lg p-6"
        >
          <button
            style={{
              backgroundColor: "crimson",
              borderRadius: "50%",
              float: "right",
              width: "40px",
              height: "40px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
            }}
            type="button"
            className="btn m-5"
            onClick={() => {
              setModalOpen(false);
              setSelectedField(null);
            }}
          >
            X
          </button>
          <main className="flex-1">
            <Formik
              initialValues={INITIAL_VALUES}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form className="space-y-8 divide-y divide-gray-200">
                  <div className="mt-6 mb-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    {/* Name */}
                    <div className="sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                      {touched.name && errors.name && (
                        <p className="text-red-500 text-sm">{errors.name}</p>
                      )}
                    </div>

                    {/* Tag */}
                    <div className="sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-700">
                        Tag
                      </label>
                      <input
                        type="text"
                        name="tag"
                        value={values.tag}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                      {touched.tag && errors.tag && (
                        <p className="text-red-500 text-sm">{errors.tag}</p>
                      )}
                    </div>

                    {/* Longitude */}
                    <div className="sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-700">
                        Longitude
                      </label>
                      <input
                        type="text"
                        name="location.lang"
                        value={values.location.lang}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                      {touched.location?.lang && errors.location?.lang && (
                        <p className="text-red-500 text-sm">
                          {errors.location.lang}
                        </p>
                      )}
                    </div>

                    {/* Latitude */}
                    <div className="sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-700">
                        Latitude
                      </label>
                      <input
                        type="text"
                        name="location.lat"
                        value={values.location.lat}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                      {touched.location?.lat && errors.location?.lat && (
                        <p className="text-red-500 text-sm">
                          {errors.location.lat}
                        </p>
                      )}
                    </div>

                    {/* Area */}
                    <div className="sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-700">
                        Area (in meters)
                      </label>
                      <input
                        type="number"
                        name="area"
                        value={values.area}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                      {touched.area && errors.area && (
                        <p className="text-red-500 text-sm">{errors.area}</p>
                      )}
                    </div>

                    {/* Soil */}
                    <div className="sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-700">
                        Soil Type
                      </label>
                      <input
                        type="text"
                        name="soil"
                        value={values.soil}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                      {touched.soil && errors.soil && (
                        <p className="text-red-500 text-sm">{errors.soil}</p>
                      )}
                    </div>

                    {/* Gat Number */}
                    <div className="sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-700">
                        Gat Number
                      </label>
                      <input
                        type="text"
                        name="gatNo"
                        value={values.gatNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                      {touched.gatNo && errors.gatNo && (
                        <p className="text-red-500 text-sm">{errors.gatNo}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <FileUploadComponent
                      uploadedFiles={uploadedFiles}
                      setUploadedFiles={setUploadedFiles}
                      documentCategory={"FIELD"}
                      isEditMode={selectedField ? true : false}
                    />
                  </div>
                  <div className="pt-5 pb-4">
                    <PolygonMapComponent
                      onPolygonComplete={({ area, coordinates }) => {
                        setFieldValue(
                          "location.lat",
                          coordinates[0]?.lat || ""
                        );
                        setFieldValue(
                          "location.lang",
                          coordinates[0]?.lng || ""
                        );
                        setFieldValue("area", area);
                      }}
                      onAddressSelect={(addressData) => {
                        setFieldValue("location.lat", addressData.lat);
                        setFieldValue("location.lang", addressData.lng);
                      }}
                    />
                  </div>
                  <div className="pt-5 pb-4">
                    <div
                      className={
                        isMobile
                          ? "flex justify-center mx-auto"
                          : "flex justify-end"
                      }
                    >
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn bg-[#00AB55] shadow-lg shadow-green-500/50 hover:bg-[#00AB55] text-white"
                      >
                        {selectedField ? "Update" : "Submit"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
        </div>
      </Transition>
    </>
  );
}

export default FieldModal;
