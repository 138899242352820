import React, { useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { Transition } from "@headlessui/react";
import { Viewer } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const DocumentInfo = ({
  userDoc,
  deleteModalOpen,
  onDeleteConfirm,
  onDeleteCancel,
  userModalOpen,
  userData,
  onUserModalClose,
  onRefetch,
}) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocType, setSelectedDocType] = useState("");

  const handleImageClick = (url, type) => {
    setSelectedFile(url);
    setSelectedDocType(type);
    setImageModalOpen(true);
  };

  return (
    <details className="text-[#00AB55] mt-5 mb-5">
      <summary className="text-xl font-bold">View attached document</summary>
      <div className="overflow-x-auto mt-5">
        <table className="min-w-full border border-gray-200">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 text-left text-sm font-large text-gray-500 border-b border-gray-300">
                File Name
              </th>
              <th className="px-6 py-3 text-left text-sm font-large text-gray-500 border-b border-gray-300">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {userDoc.map((doc, index) => (
              <tr key={index}>
                <td className="py-2 px-4">{doc.name || "N/A"}</td>
                <td className="py-2 px-4">
                  {/* {doc.documentType === "JPEG Image" ? ( */}
                  <button
                    onClick={() => handleImageClick(doc.url, doc.documentType)}
                    className="text-blue-500 underline"
                  >
                    View
                  </button>
                  {/* ) } */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {imageModalOpen && (
        <Transition
          show={imageModalOpen}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-4 rounded-lg">
              <button
                className="absolute top-2 right-2 text-white font-bold bg-red-500 m-2"
                style={{ width: "30px", height: "30px", borderRadius: "100%" }}
                onClick={() => setImageModalOpen(false)}
              >
                X
              </button>
              {selectedDocType === ("JPEG Image" || "PNG Image") ? (
                <img
                  src={selectedFile}
                  style={{ height: "80vh", width: "auto" }}
                  alt="Selected"
                  className="max-w-full max-h-full rounded-lg"
                />
              ) : (
                <>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        height: "100vh",
                        width:"100vh",
                        overflow: "hidden",
                      }}
                    >
                      <div style={{ height: "90%", width: "100%" }}>
                        <Viewer fileUrl={selectedFile} />
                      </div>
                    </div>
                  </Worker>
                </>
              )}
            </div>
          </div>
        </Transition>
      )}
    </details>
  );
};

export default DocumentInfo;
