import resolveConfig from "tailwindcss/resolveConfig";
export const signInEnpoint = process.env.REACT_APP_LOGIN_ENDPOINT;
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const EndPoint = process.env.REACT_APP_MEDIA_ENDPOINT;
export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig("./src/css/tailwind.config.js");
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => value;
// Intl.NumberFormat("en-US", {
//   style: "number",
//   // currency: "USD",
//   maximumSignificantDigits: 3,
//   notation: "compact",
// }).format(value);

export function isInstalled() {
  if (
    window.navigator.standalone === true ||
    window.matchMedia("(display-mode: standalone)").matches
  ) {
    console.log("isInstalled: true. Already in standalone mode");
    return true;
  }
  console.log("isInstalled: false.");
  return false;
}

export const loadGoogleMapsScript = (src) => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve(); // Script is already loaded
      return;
    }

    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      if (window.google && window.google.maps) {
        resolve();
      } else {
        reject(new Error("Google Maps script loaded but 'google' is undefined."));
      }
    };

    script.onerror = () => reject(new Error("Failed to load Google Maps script."));
    document.head.appendChild(script);
  });
};


