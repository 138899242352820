import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../utils/Utils";
import { Viewer } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { type } from "@testing-library/user-event/dist/type";

const FileUploadComponent = ({
  uploadedFiles,
  setUploadedFiles,
  documentCategory,
  isEditMode,
}) => {
  const [fileType, setFileType] = useState("");
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocType, setSelectedDocType] = useState("");
  const fileTypeLabels = {
    "application/pdf": "PDF",
    "image/jpeg": "JPEG Image",
    "image/png": "PNG Image",
  };

  // Ensure the `pageUrl` is available for both new and existing files (for image preview)
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const fileObjects = files.map((file) => ({
      name: file.name,
      details: file,
      pageUrl: URL.createObjectURL(file), // Used for new files being uploaded
      documentType: fileTypeLabels[file.type] || "Unknown",
      documentCategory: documentCategory,
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...fileObjects]);
  };

  const openFileInNewTab = (file) => {
    if (file.url) {
      window.open(file.url, "_blank");
    }
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const fileTypeOptions = {
    "": "",
    Images: "image/*",
    Documents: ".pdf,.doc,.docx",
  };

  const deleteFile = async (index, file) => {
    if (isEditMode && file?._id) {
      try {
        await deleteDocumentApi(file?._id);
        setUploadedFiles((prevFiles) =>
          prevFiles.filter((_, i) => i !== index)
        );
      } catch (error) {
        console.log("Error deleting files from server:", error);
      }
    } else {
      setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    }
  };

  const deleteDocumentApi = async (fileId) => {
    const token = localStorage.getItem("token");
    const endpoint = `${baseUrl}/document/${fileId}`;
    try {
      const response = await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        toast.success("Document deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      throw error;
    }
  };

  const handleImageClick = (file, type) => {
    const imageUrl = file.pageUrl || file.url; 
    setSelectedFile(imageUrl);
    setSelectedDocType(type);
    setImageModalOpen(true);
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      setSelectedFile(uploadedFiles[0].pageUrl || uploadedFiles[0].url);
    }
  }, [uploadedFiles]);

  return (
    <div className="mt-6">
      <div className="flex items-center space-x-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Upload Documents
        </h3>
        <div>
          <label htmlFor="fileType" className="sr-only">
            Select File Type
          </label>
          <select
            id="fileType"
            value={fileType}
            onChange={handleFileTypeChange}
            style={{
              padding: "5px 30px 5px 5px",
            }}
            className="py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#008542] focus:border-[#008542]"
          >
            <option value="">All Files</option>
            <option value="Images">Images</option>
            <option value="Documents">Documents</option>
          </select>
        </div>
        <div>
          <input
            type="file"
            multiple
            id="fileUpload"
            onChange={handleFileUpload}
            accept={fileTypeOptions[fileType]}
            className="hidden"
          />
          <label
            htmlFor="fileUpload"
            className="inline-block px-4 py-2 bg-[#00AB55] text-white rounded-md shadow-md cursor-pointer hover:bg-[#00AB55] transition-colors"
          >
            Choose Files
          </label>
        </div>
      </div>
      <div className="mt-6">
        {uploadedFiles?.length > 0 && (
          <table className="min-w-full border-collapse border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th
                  className="border border-gray-300 px-4 py-2 text-left"
                  style={{ width: "60%" }}
                >
                  File Name
                </th>
                <th
                  className="border border-gray-300 px-4 py-2 text-left"
                  style={{ width: "20%" }}
                >
                  File Type
                </th>
                <th
                  className="border border-gray-300 px-4 py-2 text-center"
                  style={{ width: "20%" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {uploadedFiles?.map((file, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2">
                    <span
                      className="text-blue-500 cursor-pointer hover:underline"
                      onClick={() => handleImageClick(file, file.documentType)} 
                    >
                      {file.name}
                    </span>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {file.documentType || "Unknown"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    <button
                      type="button"
                      onClick={() => deleteFile(index, file)}
                      className="text-red-500 hover:text-red-700 text-sm"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {imageModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg">
            <button
              className="absolute top-2 right-2 text-white font-bold bg-red-500 m-2"
              style={{ width: "30px", height: "30px", borderRadius: "100%" }}
              onClick={() => setImageModalOpen(false)}
            >
              X
            </button>
            {selectedDocType === ("JPEG Image" || "PNG Image") ? (
              <img
                src={selectedFile}
                style={{ height: "80vh", width: "auto" }}
                alt="Selected"
                className="max-w-full max-h-full rounded-lg"
              />
            ) : (
              <>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      height: "100vh",
                      width: "100vh",
                      overflow: "hidden",
                    }}
                  >
                    <div style={{ height: "90%", width: "100%" }}>
                      {" "}
                      <Viewer fileUrl={selectedFile} />
                    </div>
                  </div>
                </Worker>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
