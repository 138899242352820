import { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, getIn } from "formik";
import * as Yup from "yup";
import { baseUrl, EndPoint } from "../../utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Transition } from "@headlessui/react";
import useBreakpoint from "../../utils/useBreakpoint";
import FileUploadComponent from "./FileUploadComponent";
import jwtDecode from "jwt-decode";
import PolygonMapComponent from "../PolygonMapComponent";

const INITIAL_VALUES = {
  name: "",
  address: {
    location: { lang: "", lat: "" },
    localArea: "",
    taluka: "",
    district: "",
    village: "",
    state: "",
    country: "",
    pincode: "",
  },
  tag: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  tag: Yup.string().required("Tag is required"),
  address: Yup.object().shape({
    // location: Yup.object({
    //   lang: Yup.string()
    //     .required("Longitude is required")
    //     .matches(/^-?\d+(\.\d+)?$/, "Invalid longitude"),
    //   lat: Yup.string()
    //     .required("Latitude is required")
    //     .matches(/^-?\d+(\.\d+)?$/, "Invalid latitude"),
    // }),
    localArea: Yup.string().required("Local Area is required"),
    village: Yup.string().required("Village is required"),
    taluka: Yup.string().required("Taluka is required"),
    district: Yup.string().required("District is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    pincode: Yup.string()
      .required("Pincode is required")
      .matches(/^\d{6}$/, "Invalid pincode"),
  }),
});

function FarmModal({
  userData,
  setModalOpen,
  modalOpen,
  selectedFarm,
  setSelectedFarm,
  setRefetch,
}) {
  const modalContent = useRef(null);
  const { isMobile } = useBreakpoint();
  const currentFarmerId = localStorage.getItem("currentFarmerId");
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const formatSelectedFarm = () => {
    if (selectedFarm) {
      return {
        name: selectedFarm?.name || "",
        tag: selectedFarm?.tag || "",
        address: {
          location: {
            lang: selectedFarm?.address?.location?.lang || "",
            lat: selectedFarm?.address?.location?.lat || "",
          },
          localArea: selectedFarm?.address?.localArea || "",
          taluka: selectedFarm?.address?.taluka || "",
          district: selectedFarm?.address?.district || "",
          village: selectedFarm?.address?.village || "",
          state: selectedFarm?.address?.state || "",
          country: selectedFarm?.address?.country || "",
          pincode: selectedFarm?.address?.pincode || "",
        },
        documents: selectedFarm?.farm_doc[0]?.url || "",
      };
    }
    return INITIAL_VALUES;
  };

  const uploadToCdnDocuments = async (uploadedFiles) => {
    const updatedfiles = [...uploadedFiles];

    for (let i = 0; i < uploadedFiles.length; i++) {
      let formData = new FormData();
      formData.append("item", uploadedFiles[i]?.details);
      const endPoint = EndPoint;
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const userId = decoded?.id;
      try {
        const response = await axios.post(endPoint, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
          },
        });
        // setImageData(response.data);

        const url = response?.data?.result?.url;
        if (url) {
          updatedfiles[i] = {
            ...updatedfiles[i],
            url: url,
            userId: userId,
          };
        }
      } catch (error) {
        console.log(error);
      }
    }
    const finalFiles = updatedfiles?.map((file) => ({
      name: file.name,
      documentType: file.documentType,
      url: file.url,
      userId: file.userId,
      documentCategory: file.documentCategory,
    }));

    return finalFiles;
  };

  const documentSubmit = async (documentsData) => {
    const token = localStorage.getItem("token");

    const endpoint = `${baseUrl}/document`;

    let documentsIdData = [];

    try {
      const response = await axios.post(endpoint, documentsData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      documentsIdData = response?.data?.body || [];
      toast.success("Documents added suucessfully!");
    } catch (error) {
      console.error("Error uploading documents:", error);
    }

    return documentsIdData;
  };

  useEffect(() => {
    if (selectedFarm) {
      setUploadedFiles(selectedFarm?.farm_doc || []);
    }
  }, [selectedFarm]);
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (modalOpen && keyCode === 27) {
        setModalOpen(false);
        if (setSelectedFarm) {
          setSelectedFarm(null);
        }
      }
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [modalOpen, setSelectedFarm, setModalOpen]);
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const token = localStorage.getItem("token");

    let documentsIds = [];

    if (selectedFarm) {
      const newUploadedFiles = uploadedFiles?.filter((file) => !file._id);
      const filesdeleted = uploadedFiles?.filter((file) => file._id);
      const filesIds = filesdeleted?.map((item) => item._id);

      const documentsData =
        newUploadedFiles.length > 0
          ? await uploadToCdnDocuments(newUploadedFiles)
          : [];

      const documentsIdsResponse =
        documentsData.length > 0 ? await documentSubmit(documentsData) : [];

      const newDocumentIds = documentsIdsResponse?.map((item) => item._id);

      documentsIds = [...filesIds, ...newDocumentIds];
    } else {
      const documentsData = uploadedFiles
        ? await uploadToCdnDocuments(uploadedFiles)
        : [];

      const documentsIdsResponse = documentsData
        ? await documentSubmit(documentsData)
        : [];

      documentsIds = documentsIdsResponse?.map((item) => item._id);
    }

    values["documents"] = documentsIds;

    const finalValues = {
      ...values,
      farmerId: currentFarmerId,
      documents: documentsIds.length > 0 ? documentsIds : [],
      address: {
        ...values.address,
        location: {
          ...values.address.location,
          lat: values.address.location.lat.toString(),
          lang: values.address.location.lang.toString(),
        },
      },
    };

    const addMessage = selectedFarm
      ? "Updating farm details..."
      : "Adding farm details...";
    const successMessage = selectedFarm
      ? "Farm details updated successfully 👍"
      : "Farm details added successfully 👍";
    const errorMessage = selectedFarm
      ? "Error updating farm details 🤯"
      : "Error adding farm details 🤯";

    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const endpoint = `${baseUrl}/farm/${selectedFarm?._id || ""}`;
          const method = selectedFarm ? axios.patch : axios.post;
          const response = await method(endpoint, finalValues, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.status === 200) {
            resolve(response);
            setRefetch(true);
            setModalOpen(false);
            if (setSelectedFarm) {
              setSelectedFarm(null);
            }
            resetForm();
          }
        } catch (error) {
          reject(error);
          console.error(error);
        }
      }),
      {
        pending: addMessage,
        success: successMessage,
        error: errorMessage,
      }
    );
    setSubmitting(false);
  };

  return (
    <>
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      <Transition
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white overflow-auto max-w-6xl w-full max-h-full rounded shadow-lg p-6"
          style={{ maxHeight: "90vh" }}
        >
          <button
            // style={{
            //   backgroundColor: "crimson",
            //   borderRadius: "50%",
            //   float: "right",
            //   width: "40px",
            //   height: "40px",
            //   color: "white",
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   fontWeight: "bold",
            // }}
            type="button"
            className="btn m-5"
            style={{
              float: "right",
              background: "crimson",
              color: "white",
              borderRadius: "100%",
              width: "40px",
            }}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            X
          </button>
          <main className="flex-1">
            <Formik
              initialValues={formatSelectedFarm()}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form className="space-y-8 divide-y divide-gray-200">
                  <div className="mt-6 mb-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    {[
                      "name",
                      "tag",
                      "address.location.lang",
                      "address.location.lat",
                      "address.localArea",
                      "address.village",
                      "address.taluka",
                      "address.district",
                      "address.state",
                      "address.country",
                      "address.pincode",
                    ].map((name, index) => {
                      const label = name
                        .split(".")
                        .pop()
                        .replace(/([a-z])([A-Z])/g, "$1 $2");
                      const fieldError = getIn(errors, name);
                      const fieldTouched = getIn(touched, name);
                      return (
                        <div key={index} className="sm:col-span-3">
                          <label className="block text-sm font-medium text-gray-700">
                            {label.charAt(0).toUpperCase() + label.slice(1)}
                          </label>
                          <input
                            type="text"
                            name={name}
                            value={getIn(values, name)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          {fieldTouched && fieldError && (
                            <p className="text-red-500 text-sm">{fieldError}</p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="pt-5 pb-4">
                    <FileUploadComponent
                      uploadedFiles={uploadedFiles}
                      setUploadedFiles={setUploadedFiles}
                      documentCategory={"FARM"}
                      isEditMode={selectedFarm ? true : false}
                    />
                    <PolygonMapComponent
                      onPolygonComplete={({ area, coordinates }) => {
                        setFieldValue(
                          "address.location.lat",
                          coordinates[0]?.lat || ""
                        );
                        setFieldValue(
                          "address.location.lang",
                          coordinates[0]?.lng || ""
                        );
                        setFieldValue("area", area);
                      }}
                      onAddressSelect={(addressData) => {
                        setFieldValue(
                          "address.localArea",
                          addressData.localArea
                        );
                        setFieldValue("address.village", addressData.village);
                        setFieldValue("address.district", addressData.district);
                        setFieldValue("address.taluka", addressData.taluka);
                        setFieldValue("address.state", addressData.state);
                        setFieldValue("address.country", addressData.country);
                        setFieldValue("address.pincode", addressData.pincode);
                        setFieldValue("address.location.lat", addressData.lat);
                        setFieldValue("address.location.lang", addressData.lng);
                      }}
                    />
                  </div>
                  <div className="pt-5 pb-4">
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn bg-[#00AB55] shadow-lg shadow-green-500/50 hover:bg-[#00AB55] text-white"
                      >
                        {selectedFarm ? "Update" : "Submit"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
        </div>
      </Transition>
    </>
  );
}

export default FarmModal;
